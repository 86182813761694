import { useContext } from 'react';
import get from 'lodash/get';
import { DictionaryContext } from '@/components';

export const useTranslation = () => {
  const { dictionary } = useContext(DictionaryContext);

  const t = (key: string, defValue?: string) => {
    const value = get(dictionary, key, defValue) as string;

    return value ?? key;
  };

  return { t };
};
