import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { User } from '@/interfaces/user';

interface UserState {
  user?: User;
  setUser: (user: User) => void;
}

export const useUser = create<UserState>()(
  devtools(
    persist(
      (set) => ({
        setUser: (user) => set(() => ({ user }), false, 'set-user'),
      }),
      { name: 'user-storage' },
    ),
  ),
);
