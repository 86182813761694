import { NavLink, rem } from '@mantine/core';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';
import cx from 'classnames';
import classes from './NavigationItem.module.css';
import { useUser } from '@/hooks/useUser';
import { useStore } from '@/hooks/store';

interface Props {
  label: string;
  icon?: ReactNode;
  path: string;
  permissions?: string[];
  children?: ReactNode;
}

export const NavigationItem = ({
  label,
  icon,
  path,
  permissions,
  children,
}: Props) => {
  const user = useUser((state) => state.user);
  const { currentRole, selectRole } = useStore();
  const pathname = usePathname();

  if (permissions && !permissions?.includes(currentRole ?? '')) return null;

  return (
    <NavLink
      className={cx({ [classes.active]: pathname.includes(path) })}
      href={path}
      label={label}
      leftSection={icon}
      childrenOffset={children ? rem(30) : undefined}
      color="gray"
      variant="subtle"
      component={Link}
      active={true}
    >
      {children}
    </NavLink>
  );
};
