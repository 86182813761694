'use client';

import React, { useEffect, useState } from 'react';
import { Modal, rem, Flex, Grid, Text, ScrollArea } from '@mantine/core';
import classes from './RoleModal.module.css';
import { useStore } from '@/hooks/store';
import { useUser } from '@/hooks/useUser';
import { Button } from '@/components/Button';
import { useRouter } from 'next/navigation';
import {
  UserCircle,
  Kanban,
  TreeStructure,
  GlobeHemisphereEast,
  Nut,
  GearSix,
  UserGear,
  Toolbox,
  Hammer,
  Coins,
  Stack,
} from '@phosphor-icons/react/dist/ssr';

const ICONS: any = {
  ADMINISTRATORS: {
    icon: <UserCircle size={35} />,
    label: 'Administrātors',
  },
  GENERALUZNEMEJS: {
    icon: <GlobeHemisphereEast size={35} />,
    label: 'Ģenerāluzņēmējs',
  },
  'MATERIALU_RAZOTAJS (LEGAL PERSON)': {
    icon: <Stack size={35} />,
    label: 'Materiālu ražotājs (Juridiska persona)',
  },
  'MATERIALU_RAZOTAJS (EMPLOYEE)': {
    icon: <Nut size={35} />,
    label: 'Materiālu ražotājs (Darbinieks)',
  },
  'MEHANISMU_IZNOMATAJS (LEGAL PERSON)': {
    icon: <GearSix size={35} />,
    label: 'Mehānismu iznomātājs (Juridiska persona)',
  },
  'MEHANISMU_IZNOMATAJS (EMPLOYEE)': {
    icon: <UserGear size={35} />,
    label: 'Mehānismu iznomātājs (Darbinieks)',
  },
  APAKSUZNEMEJS: {
    icon: <TreeStructure size={35} />,
    label: 'Apakšuzņēmējs',
  },
  'PROJEKTU_VADITAJS (EMPLOYEE)': {
    icon: <Kanban size={35} />,
    label: 'Projektu vadītājs',
  },
  'DARBU_VADITAJS (EMPLOYEE)': {
    icon: <Toolbox size={35} />,
    label: 'Darbu vadītājs (Darbinieks)',
  },
  'BRIGADIERIS (EMPLOYEE)': {
    icon: <Hammer size={35} />,
    label: 'Brigadieris',
  },
  'TAMETAJS (EMPLOYEE)': {
    icon: <Coins size={35} />,
    label: 'Tāmētājs',
  },
};

export const RoleModal = () => {
  const user = useUser((state) => state.user);
  const router = useRouter();

  const {
    currentRole,
    selectRole,
    RoleSelectorVisible,
    setRoleSelectorVisible,
  } = useStore();
  const [roles, setRoles] = useState<string[]>([]);
  const [selectedRole, setSelectedRole] = useState(null);

  const handleRoleClick = (role: any) => {
    setSelectedRole(role);
  };

  const confirmRoleSelection = () => {
    // @ts-ignore
    selectRole(selectedRole);
    setRoleSelectorVisible(false);
    router.push('/home');
  };

  const handleClose = () => {
    setRoleSelectorVisible(false);
    if (!currentRole) {
      router.push('/logout');
    }
  };

  useEffect(() => {
    if (user?.role) {
      const rolesArray = user.role.split(',').map((role) => role.trim());
      setRoles(rolesArray);
    }
    if (currentRole === '') {
      setRoleSelectorVisible(true);
    }
  }, [user]);

  return (
    <>
      {RoleSelectorVisible ? (
        <Modal
          size="auto"
          opened={true}
          onClose={handleClose}
          withCloseButton={true}
          centered
          radius={20}
          overlayProps={{
            backgroundOpacity: 0.55,
            blur: 3,
          }}
        >
          <Flex w={rem(650)} h={rem(600)} justify="center" align="center">
            <Flex direction="column" w={rem(400)}>
              <Text fz={rem(25)} fw={600} className={classes.title}>
                Izvēlēties lomu
              </Text>
              <ScrollArea
                w={rem(400)}
                h={rem(230)}
                mb={rem(30)}
                mt={rem(20)}
                type="never"
              >
                {roles.map((role: any) => (
                  <Flex
                    align="center"
                    h={rem(110)}
                    mb={rem(10)}
                    className={`${classes.selection} ${
                      selectedRole === role ? classes.selected : ''
                    }`}
                    onClick={() => handleRoleClick(role)}
                  >
                    <Grid ml={rem(20)} justify="flex-start" align="center">
                      <Grid.Col span={1} mr={rem(25)}>
                        {ICONS[role].icon}
                      </Grid.Col>
                      <Grid.Col span={3}>
                        <Flex direction={'column'} w={rem(300)}>
                          <Text fz={rem(15)} fw={600}>
                            {ICONS[role].label}
                          </Text>
                          <Text fz={rem(12)} fw={400} c="grey">
                            {user?.company_name}
                          </Text>
                        </Flex>
                      </Grid.Col>
                    </Grid>
                  </Flex>
                ))}
              </ScrollArea>
              <Button disabled={!selectedRole} onClick={confirmRoleSelection}>
                Turpināt
              </Button>
            </Flex>
          </Flex>
        </Modal>
      ) : null}
    </>
  );
};
