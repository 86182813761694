export const ROLES = {
  ADMINISTRATORS: 'ADMINISTRATORS',

  MATERIALU_RAZOTAJS_LEGAL: 'MATERIALU_RAZOTAJS (LEGAL PERSON)',
  MATERIALU_RAZOTAJS_EMPLOYEE: 'MATERIALU_RAZOTAJS (EMPLOYEE)',
  GENERALUZNEMEJS: 'GENERALUZNEMEJS',
  MEHANISMU_IZNOMATAJS_LEGAL: 'MEHANISMU_IZNOMATAJS (LEGAL PERSON)',
  MEHANISMU_IZNOMATAJS_EMPLOYEE: 'MEHANISMU_IZNOMATAJS (EMPLOYEE)',
  APAKSUZNEMEJS: 'APAKSUZNEMEJS',
  PROJEKTU_VADITAJS: 'PROJEKTU_VADITAJS (EMPLOYEE)',
  DARBU_VADITAJS: 'DARBU_VADITAJS (EMPLOYEE)',
  BRIGADIERIS: 'BRIGADIERIS (EMPLOYEE)',
  TAMETAJS: 'TAMETAJS (EMPLOYEE)',

  //izdzest vecas lomas
  MATERIALU_RAZOTAJS: 'MATERIALU_RAZOTAJS',
  MEHANISMU_RAZOTAJS: 'MEHANISMU_RAZOTAJS',
  PROJEKTETAJS: 'PROJEKTETAJS',
};
