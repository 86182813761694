import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type PeristentStore = {
  currentRole: string;
  selectRole: (role: string) => void;
  RoleSelectorVisible: boolean;
  setRoleSelectorVisible: (value: boolean) => void;
};

type NonPeristentStore = {
  loading: boolean;
  isLoading: (value: boolean) => void;
};

export const useStore = create<PeristentStore>()(
  persist(
    (set) => ({
      currentRole: '',
      selectRole: (role: string) => set({ currentRole: role }),
      RoleSelectorVisible: false,
      setRoleSelectorVisible: (value: boolean) =>
        set({ RoleSelectorVisible: value }),
    }),
    {
      name: 'Store',
    },
  ),
);

export const UseNonPersistentStore = create<NonPeristentStore>()((set) => ({
  loading: false,
  isLoading: (value: boolean) => set({ loading: value }),
}));
